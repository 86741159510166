
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "ShipsSelect",
  props: {
    vessel_id: {
      type: [Number, Array],
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    load: {
      type: Boolean,
      default: false,
    },
    all: {
      type: Boolean,
      default: false,
    },
    mounted: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:vessel_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const vesselData = computed(() => store.getters.Vessel);
    const inputShip = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isShipRequired = (value) => {
      if (props.required && !value) {
        return t("rshipid");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "ship",
      isShipRequired
    );

    const getShip = () => {
      if (props.load && !vesselData.value.length) {
        ApiService.get("/api/vessel/lists").then(({ data }) => {
          store.commit("setVessel", data.vessel);
          inputShip.value.list = data.vessel;
          inputShip.value.options = data.vessel;
        });
      } else {
        inputShip.value.list = vesselData.value.slice();
        inputShip.value.options = vesselData.value.slice();
      }
    };
    const selectShip = (query) => {
      if (query !== "") {
        inputShip.value.loading = true;
        setTimeout(() => {
          inputShip.value.loading = false;
          inputShip.value.options = inputShip.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!inputShip.value.options.length) {
            ApiService.query(`/api/vessel/lists`, {
              params: { per_page: 10, name: query },
            }).then(({ data }) => {
              data.vessel.forEach((vessel) => {
                store.commit("addVessel", vessel);
              });
              inputShip.value.list = vesselData.value;
              inputShip.value.options = data.vessel;
            });
          }
        }, 200);
      } else {
        inputShip.value.options = vesselData.value;
      }
    };

    watch(
      () => props.vessel_id,
      (first) => {
        element_id.value = first;
      }
    );

    onMounted(() => {
      element_id.value = props.vessel_id;
      if (props.mounted) {
        getShip();
      } else {
        inputShip.value.list = vesselData.value;
        inputShip.value.options = vesselData.value;
      }
    });

    return {
      element_id,
      inputShip,
      errorMessage,
      getShip,
      selectShip,
    };
  },
};
